<template>
  <div>
    <div>
      <!-- Title -->
      <div class="page">
        <div class="d-flex-center-between">
          <div class="d-flex-center-middle">
            <div>
              <div class="exam-title type-title">
                <div :class="nextTitle ? 'text51 fade-out' : 'text51'">
                  {{ title }}
                </div>
              </div>
              <!-- <div class="text-center">
                <div class="exam-title-small">AP</div>
              </div> -->
            </div>
            <div class="exam-title">
              Mocks
            </div>
          </div>
          <div class="middle-title">for</div>
          <div class="role-title">
            <router-link :to="{ name: 'LandingPageStudents' }">
              <div class="role-title-button">
                STUDENTS
              </div>
            </router-link>
            <router-link :to="{ name: 'LandingPageEducators' }">
              <div class="role-title-button">
                EDUCATORS
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  metaInfo() {
    return {
      title: this.CompanyName
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      title: "",
      nextTitle: "",
      message: ["SAT", "ACT", "AP", "TOEFL", "IELTS"]
    };
  },
  computed: {},
  watch: {},

  mounted() {
    this.typeAnimation();
  },

  methods: {
    typeAnimation() {
      let index = 0;
      let mIndex = 0;
      let time = 200;
      // clearInterval(this.interval);
      this.interval = setInterval(() => {
        if (index < this.message[mIndex].length) {
          if (index == 0) {
            this.nextTitle = false;
          }
          this.title += this.message[mIndex][index++];
        } else {
          index++;
          this.nextTitle = true;
          if (index == this.message[mIndex].length + 8) {
            this.title = "";
            index = 0;
            mIndex++;
            if (mIndex === this.message.length) {
              mIndex = 0;
            }
          }
        }
      }, time); // 每0.1秒添加一个字符
    }
  }
};
</script>
<style scoped>
.fade-out {
  animation: fadeOut 1s linear infinite;
  animation-fill-mode: forwards; /* 保持最后一帧的状态 */
  animation-iteration-count: 1; /* 动画只播放一次 */
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.text51 {
  width: 100%;
  color: var(--themeColor);
  white-space: nowrap;
  /* color: #5ba5fb; */
  background: linear-gradient(
    269deg,
    #fa12e3 1.83%,
    #7612fa 53.68%,
    #12d0fa 106.48%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
<style scoped>
.page {
  width: 100%;
  height: 720px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--themeColor);
  transition: all 0.3s ease;
}
.tab-details {
  display: flex;
}
.type-title {
  width: 17rem;
  text-align: right;
}
.role-title-button {
  margin-top: 3rem;
  color: white !important;
  font-size: 3.5rem;
  font-weight: 500;
  background-color: var(--themeColor);
  /* display: inline-block; */
  padding: 1.5rem 5rem;
  border-radius: 1.5rem;
  text-align: center;
  background-image: linear-gradient(to right, #5ba5fb, var(--themeColor));
  cursor: pointer;
  transition: background-image 0.5s ease;
  /* border: 1px solid var(--themeColor); */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: box-shadow 0.3s, transform 0.3s;
}
.role-title a {
  color: white !important;
  text-decoration: none;
}
.role-title-button:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-1rem);
}

.exam-title,
.role-title,
.exam-title-small {
  color: var(--themeColor);
  font-weight: 700;
  font-size: 6rem;
  cursor: pointer;
}
.exam-title,
.role-title {
  line-height: 5rem;
  margin-bottom: 1rem;
}
.exam-title-small {
  font-size: 2rem;
  border: 1px solid var(--themeColor);
  border-radius: 0.6rem;
  padding: 0.2rem;
  width: 5rem;
  text-align: center;
  margin: 0 auto;
  opacity: 0.3;
  transition: box-shadow 0.3s, transform 0.3s;
}
.middle-title {
  color: var(--themeColor);
  font-weight: 700;
  font-size: 2rem;
  font-style: italic;
  margin: 0 4rem;
}
.d-flex-center-between {
  display: flex;
  /* display: -webkit-flex; */
  align-items: center;
  justify-content: center;
}
.d-flex-center-middle {
  display: flex;
  /* display: -webkit-flex; */
  align-items: center;
  justify-content: middle;
}
.page2-title {
  color: var(--themeColor);
  font-weight: 700;
  font-size: 2.5rem;
  font-style: italic;
}
.page2-small-title {
  color: var(--themeColor);
  font-weight: 400;
  font-size: 1rem;
  margin-top: 1rem;
}
.page2-button {
  margin-top: 3rem;
  color: white !important;
  font-size: 1rem;
  font-weight: 500;
  background-color: var(--themeColor);
  display: inline-block;
  padding: 1rem 5rem;
  border-radius: 0.5rem;
  background-image: linear-gradient(to right, #5ba5fb, #d90ceb);
  cursor: pointer;
  transition: background-image 0.5s ease;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: box-shadow 0.3s, transform 0.3s;
}
.page2-button:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}
.page2-small-title2 {
  color: var(--themeColor);
  font-weight: 500;
  font-size: 1rem;
  margin-top: 1rem;
}
</style>
<style scoped>
.stories-card-box {
  /* width: 1080px; */
  max-width: 100%;
  margin: 0 auto;
  overflow-x: auto;
  /* margin-top: 1rem; */
  white-space: nowrap; /* 确保内容不换行 */
}
.story-card {
  display: inline-block;
  width: 30%;
  width: 24rem !important;
  height: 40rem !important;
  margin: 0 0.5rem;
  border-radius: 1rem;
  background: black;
  cursor: pointer;
  position: relative;
}
.story-card:hover .story-card-footer {
  display: none;
}
.story-card-footer {
  color: white;
  display: block;
  position: absolute;
  width: 100%;
  height: 40%;
  border-radius: 0 0 1rem 1rem;
  border-block-start: 1px solid rgba(0, 0, 0, 0.3);
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.story-card-img {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  overflow: hidden;
}
.story-card:hover .story-card-video iframe {
  display: block;
}
.story-card-video {
  /* width: 100%;
  height: 100%; */
}
.story-card-video iframe {
  border-radius: 1rem;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.story-card-img img {
  display: inline-block;
  width: 100%;
  border-radius: 1rem;
}

.story-card-footer-words {
  white-space: normal;
  margin-block-start: -0.23rem;
  font-size: 1.8rem;
  line-height: 2rem;
}

/* 隐藏滚动条 */
::-webkit-scrollbar {
  display: none;
}

/* 启用滚动 */
.scrollable {
  overflow: auto;
  -webkit-overflow-scrolling: touch; /* 移动端使用触摸滚动 */
}
</style>
<style scoped>
.tabs-box {
  max-width: 100%;
  width: 80rem;
  margin: 0 auto;
  overflow: auto;
  padding-top: 1rem;
  /* padding-left: 4rem;
  padding-right: 4rem; */
  height: 11rem;
  /* display: flex; */
  justify-content: center;
  white-space: nowrap;
}
.tab {
  /* flex: 1; */
  display: inline-block;
  width: 8rem;
  text-align: center;
  cursor: pointer;
  margin: 0 1rem;
  transition: all 0.3s ease;
  vertical-align: top;
}
.tab-icon {
  width: 4rem;
  height: 4rem;
  border-radius: 0.5rem;
  background: linear-gradient(130deg, #f8f8fc, #f8f8fc);
  margin: 0 auto;
  transition: all 0.3s ease;
}
.tab-icon-img {
  height: 4rem;
  font-size: 2rem;
  line-height: 4rem;
  text-align: center;
  vertical-align: middle;
}
.active .tab-icon {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 0.6rem;
  background: var(--themeColor);
  color: white;
  box-shadow: 0 0.3rem 0.4rem rgba(0, 0, 0, 0.3);
  transform: translateY(-0.5rem);
}
.active .tab-icon-img {
  line-height: 4.5rem;
  font-size: 1.6rem;
}
.tab:hover .tab-icon {
  /* box-shadow: 0 0.3rem 0.4rem rgba(0, 0, 0, 0.3); */
}
.tab:hover {
  transform: translateY(-0.5rem);
}
.tab.active:hover {
  transform: translateY(0);
}
.active .tab-title {
  font-weight: 500;
}
.tab-title {
  margin-top: 0;
  white-space: normal;
}
.tab-content {
  background: #ccc;
  border: 1px solid var(--themeColor);
  border-radius: 1rem;
  /* height: 30rem; */
  padding: 1rem;

  margin: 0 auto;
  overflow: hidden;
  border: 1px solid transparent;
  border-radius: 1rem;
  background: linear-gradient(#fff, hsla(0, 0%, 100%, 0)) padding-box,
    linear-gradient(90deg, #45c4f9, #ff0be5) border-box;
}
.tab-content img {
  display: inline-block;
  width: 100%;
  border-radius: 0.8rem;
}
</style>
<!-- Stories -->
<style scoped>
.stories-title {
  color: var(--themeColor);
  font-weight: 700;
  font-size: 2.5rem;
  font-style: italic;
}
.stories-small-title {
  color: var(--themeColor);
  font-weight: 500;
  font-size: 1rem;
  margin-top: 1rem;
}
</style>
<!-- mainTabs -->
<style scoped>
.details-list {
  margin-bottom: 1rem;
  min-width: 30rem;
}
.detail-content {
  width: 100%;
  margin: 0;
}
.mainTab-title {
  color: var(--themeColor);
  font-weight: 700;
  font-size: 2.5rem;
  font-style: italic;
  opacity: 0.7;
  cursor: pointer;
  transition: all 0.3s ease;
}
.mainTab-title:hover {
  transform: translateY(-0.5rem);
}
.active.mainTab-title {
  opacity: 1;
}
.active.mainTab-title:hover {
  transform: translateY(0);
}
.mainTab-small-title {
  color: var(--themeColor);
  font-weight: 500;
  font-size: 1rem;
  margin-top: 0.5rem;
  transition: all 0.3s ease;
  text-align: left;
}
</style>
<style>
.detail-content {
  background: #ccc;
  border: 1px solid var(--themeColor);
  border-radius: 1rem;
  height: 30rem;
  width: 100%;
  margin: 0 auto;
  margin-left: 2rem;
}
</style>
<style scoped>
@media screen and (min-width: 1080px) {
  .page {
    /* padding: 3rem 4rem; */
    border: none;
  }
}
@media screen and (max-width: 1080px) {
  .page {
    /* padding: 2rem 1rem; */
    border-top: 1px solid #efefef;
  }
}
@media screen and (max-width: 768px) {
  .page {
    padding: 1rem 0;
  }
  .d-flex-center-between,
  .d-flex-center-middle {
    display: block;
    text-align: center;
  }
  .type-title {
    width: 100%;
    height: 5rem;
    text-align: center;
  }
  .tab-details {
    display: block;
    text-align: center;
  }
  .details-list {
    margin-bottom: 1rem;
  }
  .detail-content {
    width: 100%;
    margin: 0;
  }
  .stories-small-title {
    text-align: left;
  }
}
</style>
